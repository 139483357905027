import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithPhoneNumber,
  signInWithPopup,
  GoogleAuthProvider,
  TwitterAuthProvider
} from "firebase/auth";
import { toast } from "react-toastify";
const b64uLookup = { "/": "_", _: "/", "+": "-", "-": "+", "=": ".", ".": "=" };
export const b64uEnc = str =>
  btoa(str).replace(/(\+|\/|=)/g, m => b64uLookup[m]);
export const b64uDec = str =>
  atob(str.replace(/(-|_|\.)/g, m => b64uLookup[m]));

export const firebaseConfig = {
  apiKey: "AIzaSyBoEPzVH68kH_3QDzejYbSgvPCZUBc5Glg",
  authDomain: "leofinance.firebaseapp.com",
  databaseURL: "https://leofinance.firebaseio.com",
  projectId: "leofinance",
  storageBucket: "leofinance.appspot.com",
  messagingSenderId: "561372801507",
  appId: "1:561372801507:web:86b17589592f8ec60dd8d6"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

auth.useDeviceLanguage();

export { app, auth };

// phone login
export function loginWithPhone(phoneNumber: string) {
  const appVerifier = window.recaptchaVerifier;

  signInWithPhoneNumber(auth, phoneNumber, appVerifier)
    .then(confirmationResult => {
      // SMS sent. Prompt user to type the code from the message, then sign the
      // user in with confirmationResult.confirm(code).
      if (typeof window === "undefined") return;

      window.confirmationResult = confirmationResult;
      // ...
    })
    .catch(error => {
      // Error; SMS not sent
      // ...
      console.log(error);
    });
}

// twitter

export function verifyTwitter(method?: string) {
  const provider = new TwitterAuthProvider();

  return signInWithPopup(auth, provider)
    .then(result => {
      // This gives you a the Twitter OAuth 1.0 Access Token and Secret.
      // You can use these server side with your app's credentials to access the Twitter API.
      const credential = TwitterAuthProvider.credentialFromResult(result);
      const token = credential?.accessToken;
      //const secret = credential?.secret;

      if (!token) {
        toast("Error while authorizing.", {
          type: "error",
          theme: "dark"
        });
      }

      return result;
    })
    .catch(error => {
      console.log(error);
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.customData.email;
      // The AuthCredential type that was used.
      const credential = TwitterAuthProvider.credentialFromError(error);
      // ...
    });
}

export function verifyGoogle(method?: string) {
  const provider = new GoogleAuthProvider();

  return signInWithPopup(auth, provider)
    .then(result => {
      // This gives you a the Twitter OAuth 1.0 Access Token and Secret.
      // You can use these server side with your app's credentials to access the Twitter API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential?.accessToken;
      //const secret = credential?.secret;

      if (!token) {
        toast("Error while authorizing.", {
          type: "error",
          theme: "dark"
        });
      }

      return result;
    })
    .catch(error => {
      console.log(error);
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.customData.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      // ...
    });
}

export function sendPhoneMessage(phoneNumber: string) {
  const appVerifier = (window as any)?.recaptchaVerifier;

  return signInWithPhoneNumber(auth, `+${phoneNumber}`, appVerifier)
    .then(confirmationResult => {
      // SMS sent. Prompt user to type the code from the message, then sign the
      // user in with confirmationResult.confirm(code).
      window.confirmationResult = confirmationResult;
      // ...
    })
    .catch(error => {
      console.log(error);
      // Error; SMS not sent
      // ...
    });
}

